<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-12 text-right">
							<a-button class="btn btn-info" icon="plus" @click="onAddRecord">{{ $t('general.add') }}</a-button>
							<a-button class="btn btn-success ml5" icon="reload" @click="onReload" />
						</div>
					</div>
					<div class="row pt15">
						<div class="col-md-12">
							<a-table :columns="columns" :dataSource="usersList">
								<div slot="fullName" slot-scope="record">{{ record.name }} {{ record.surname }}</div>
								<div slot="role" slot-scope="record">
									{{ roles[record.role].label }}
								</div>
								<div slot="action" slot-scope="record">
									<a-button class="ml5" type="info" size="small" icon="edit" @click="onEditRecord(record.id)" />
									<a-button class="ml5" size="small" :icon="record.active ? 'minus-circle' : 'plus-circle'" @click="toggleRecord(record.id, record.status)" />
								</div>
							</a-table>
						</div>
					</div>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { roles } from '@/constants'

export default {
	name: 'usersListView',
	data() {
		return {
			roles,
		}
	},
	computed: {
		...mapGetters('auth', ['userData']),
		...mapGetters('users', ['usersList', 'spinnerLoader', 'spinnerLoaderLabel']),
		columns() {
			let columns = [
				{
					title: this.$t('systemModule.users.tableHeaders.id'),
					dataIndex: 'id',
					align: 'center',
				},
				{
					title: this.$t('systemModule.users.tableHeaders.fullName'),
					scopedSlots: { customRender: 'fullName' },
				},
				{
					title: this.$t('systemModule.users.tableHeaders.email'),
					dataIndex: 'email',
				},
				{
					title: this.$t('systemModule.users.tableHeaders.phone'),
					dataIndex: 'phone',
					align: 'center',
				},
				{
					title: this.$t('systemModule.users.tableHeaders.role'),
					scopedSlots: { customRender: 'role' },
					align: 'center',
				},
				{
					title: this.$t('general.actions'),
					scopedSlots: { customRender: 'action' },
					align: 'right',
				},
			]
			return columns
		},
	},
	mounted() {
		if (!this.usersList.length) {
			this.initModule()
		}
	},
	methods: {
		initModule() {
			this.$store.dispatch('users/GET')
		},
		onReload() {
			this.initModule()
		},
		onAddRecord() {
			this.$router.push('/config/users/new')
		},
		onEditRecord(id) {
			this.$store.dispatch('users/GET_ONE', id)
		},
		toggleRecord(id, status) {
			Swal.fire({
				title: this.$t('general.deleteMessage.title'),
				html: this.$t('general.deleteMessage.message'),
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: this.$t('general.yesContinue'),
				cancelButtonText: this.$t('general.cancel'),
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('users/TOGGLE', id)
				}
			})
		},
	},
}
</script>